import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { ApiService } from './api.service';
import { environment } from '../../../environments/environment';
import { ORDER_STORAGE_KEY, QUIZ_LINK } from '../constants/config';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { saveAs } from 'file-saver';
import { LocalstorageService } from './localstorage.service';

@Injectable({
  providedIn: 'root'
})
export class ProductApiService {
  private http = inject(HttpClient);
  private apiService = inject(ApiService);
  private authService = inject(AuthService);
  private router = inject(Router);
  private localStorage = inject(LocalstorageService);

  // CATEGORIES
  getCategoryBySlug(slug: string): Observable<any> {
    return this.http
      .get(`${environment.APP_ENDPOINT}/api/product/category/${slug}`)
      .pipe(catchError(this.apiService.handleError()));
  }

  // PRODUCTS
  getProducts(filtration: any): Observable<any> {
    return this.http
      .post(`${environment.APP_ENDPOINT}/api/product/all`, filtration)
      .pipe(catchError(this.apiService.handleError()));
  }

  // PRODUCT DETAIL
  getProductBySlug(slug: string): Observable<any> {
    return this.http
      .get(`${environment.APP_ENDPOINT}/api/product/${slug}`)
      .pipe(catchError(this.apiService.handleError()));
  }

  // ADD TO CART
  getSavedData() {
    return this.localStorage.getItem(ORDER_STORAGE_KEY)
      ? JSON.parse(this.localStorage.getItem(ORDER_STORAGE_KEY) || '{}')
      : {
          steps: {},
          product: {},
          plan: {},
          checkout_step: {},
          registration_step: {},
          type: null,
          order_id: null
        };
  }

  clearData() {
    if (this.localStorage.getItem(ORDER_STORAGE_KEY)) {
      this.localStorage.removeItem(ORDER_STORAGE_KEY);
    }
  }

  setData(data: any) {
    this.localStorage.setItem(ORDER_STORAGE_KEY, JSON.stringify(data));
  }

  formatingQuizData() {
    const data = this.getSavedData();
    const gender = data?.registration_step?.sex;
    if (gender === 'male' && data.steps[7]) {
      delete data.steps[7];
    }
    const selectedDoseProductValue = this.selectedDoseProduct();
    if (selectedDoseProductValue) {
      const optionId = selectedDoseProductValue?.step_id;
      const dose = data.steps[optionId].values
        ? data.steps[optionId].values[0]?.title
        : null;
      if (selectedDoseProductValue.id !== 3) {
        data.steps[19] = {
          system: true,
          text: `${selectedDoseProductValue?.title} ${dose}`,
          title: 'Which medication and which dose?',
          valid: true
        };
      }
    }
    return data;
  }

  // SAVE QUIZ FOR USER
  saveQuiz(): Observable<any> {
    const data = this.formatingQuizData();
    return this.http
      .post(`${environment.APP_ENDPOINT}/api/user/saveQuiz`, data)
      .pipe(catchError(this.apiService.handleError()));
  }

  // SAVE QUIZ BY SALES
  saveQuizBySales(): Observable<any> {
    const data = this.formatingQuizData();
    return this.http
      .post(`${environment.APP_ENDPOINT}/api/user/saveQuizSeller`, data)
      .pipe(catchError(this.apiService.handleError()));
  }

  // SAVE QUIZ FOLLOWUP
  saveFollowUpQuiz(data: any): Observable<any> {
    return this.http
      .post(`${environment.APP_ENDPOINT}/api/user/saveFollowUpQuiz`, data)
      .pipe(catchError(this.apiService.handleError()));
  }

  // QUIZ IS FOR WEIGHTLOSS
  isWeightLossQuiz(data: any): any {
    return data.type === 1 || data.type === 2 ? true : false;
  }

  // CALCULATE FITNESS QUIZ USER POINS
  fitnessPoints(steps: any): any {
    let points = 0;
    Object.values(steps).map((item: any) => {
      points += item?.values[0]?.point ? item.values[0].point : 0;
    });
    return points;
  }

  // USER ALREADY TAKE WEIGHT LOSS
  isWeightLossPatient() {
    const stepSavedData = this.getSavedData();
    if (this.isWeightLossQuiz(stepSavedData)) {
      return Object.values(stepSavedData.steps).some(
        (item: any) =>
          item?.values?.length && item.values[0]?.weight_loss_patient
      );
    }
    return null;
  }

  // GET SELECTED DOSE TYPE
  getSelectedStepDoseType() {
    const isWeightLossPatient = this.isWeightLossPatient();
    const stepSavedData = this.getSavedData();
    if (isWeightLossPatient) {
      const doseType: any = Object.values(stepSavedData.steps).find(
        (item: any) => item?.values?.length && item.values[0]?.doses
      );
      return doseType?.values?.length ? doseType.values[0] : null;
    }
    return null;
  }

  // GET SELECTED DOSE
  getSelectedStepDose() {
    const stepSavedData = this.getSavedData();
    const doseType: any = this.getSelectedStepDoseType();
    const productSelectedStepId = doseType?.step_id;
    if (productSelectedStepId) {
      const doseSelectedValue = stepSavedData.steps[productSelectedStepId];
      if (doseSelectedValue && doseSelectedValue?.values?.length) {
        return doseSelectedValue.values[0];
      }
    }
    return null;
  }

  // SELECTED DOSE PRODUCT STEP
  selectedDoseProduct() {
    const isWeightLossPatient = this.isWeightLossPatient();
    const stepSavedData = this.getSavedData();
    if (
      isWeightLossPatient &&
      stepSavedData.steps[201] &&
      stepSavedData.steps[201]?.values?.length
    ) {
      return stepSavedData.steps[201]?.values[0];
    } else {
      return null;
    }
  }

  // GET PRODUCT MED TYPE
  getMedicationType(data: any) {
    if (data.steps[103] && data.steps[103]?.values?.length) {
      return data.steps[103]?.values[0]?.id;
    } else if (data?.product?.medication_type) {
      return data?.product?.medication_type;
    } else {
      return 0;
    }
  }

  // GET SELECTED FOLLOWUP DOSE
  getSelectedStepFolloupDose() {
    const stepSavedData = this.getSavedData();
    const doseSelected =
      stepSavedData.steps[17] && stepSavedData.steps[17]?.values?.length
        ? stepSavedData.steps[17]?.values[0]
        : null;
    if (
      doseSelected &&
      doseSelected?.id === 4 &&
      stepSavedData.steps[doseSelected?.step_id]
    ) {
      if (stepSavedData.steps[doseSelected?.step_id]?.values?.length) {
        return stepSavedData.steps[doseSelected?.step_id]?.values[0];
      }
    }
    return null;
  }

  // FORMAT PRODUCT FOLLOUP
  formatFolloupOrderProduct(order: any, withPayment = false) {
    const stepSavedData = this.getSavedData();
    const product = order?.last_order?.products?.length
      ? order?.last_order?.products[0]
      : order.products[0];
    stepSavedData.product = {
      title: product?.name,
      id: product?.product_id,
      doses: product?.doses,
      with_payment: withPayment,
      medication_type: product?.medication_type,
      price: {
        price: product?.price,
        type: product?.price_type,
        med_id: order?.last_order?.dose_id
          ? order?.last_order?.dose_id
          : order?.dose_id
      }
    };
    stepSavedData.dose_id = order?.last_order?.dose_id
      ? order?.last_order?.dose_id
      : order?.dose_id;
    stepSavedData.subscription = withPayment;
    this.setData(stepSavedData);
  }

  // FORMAT DOSE FOLLOUP
  formatFolloupOrderDose(prices: any[], medId: string) {
    const stepSavedData = this.getSavedData();
    const isKickstrterDose = prices.find(
      (item: any) => item.interval === 12 && item?.med_id === medId
    );
    if (isKickstrterDose) {
      let defaultDose: any;
      if (stepSavedData.product.doses === 1) {
        defaultDose = this.getKickstrterDefaultDose(1, prices);
      } else if (stepSavedData.product.doses === 2) {
        defaultDose = this.getKickstrterDefaultDose(7.5, prices);
      }
      stepSavedData.dose_id = defaultDose?.med_id;
      stepSavedData.product.price = defaultDose;
    } else {
      const currentDose = prices.find((item: any) => item?.med_id === medId);
      if (currentDose) {
        stepSavedData.dose_id = currentDose?.med_id;
        stepSavedData.product.price = currentDose;
      }
    }
    this.setData(stepSavedData);
  }

  getKickstrterDefaultDose(doseType: number, prices: any[]): any {
    const dosePrice = prices.find((item: any) => item.quantity === doseType);
    return dosePrice;
  }

  // SET PRODUCT PRICE
  formatDefaultPrice(item: any) {
    const price = item.price.find((value: any) => value.default);
    const order = {
      id: item.id,
      title: item?.title,
      image: item?.image,
      doses: item?.doses,
      medication_type: item?.medication_type,
      price: price
    };
    return order;
  }

  // ADD PRODUCT TO CARD
  addToCart(item: any, type: string | number) {
    const isLoggedIn = this.authService.isAuthenticated();
    const stepSavedData = this.getSavedData();
    stepSavedData.product = item;
    stepSavedData.steps = {};
    stepSavedData.plan = {};
    stepSavedData.registration_step = {};
    stepSavedData.id = null;
    stepSavedData.user_id = null;
    this.localStorage.setItem(ORDER_STORAGE_KEY, JSON.stringify(stepSavedData));
    if (type) {
      this.router.navigate([QUIZ_LINK], {
        queryParams: {
          current_step: isLoggedIn ? '2' : '1',
          quiz_type: type
        }
      });
    } else {
      this.router.navigate([QUIZ_LINK], {
        queryParams: {
          current_step: isLoggedIn ? '33' : '1'
        }
      });
    }
  }

  formatingPaymentData(
    isSales: boolean,
    promocode: any,
    old_order_id?: number
  ) {
    const stepSavedData = this.getSavedData();
    const userDate = stepSavedData?.checkout_step?.user_date;
    const userTime = stepSavedData?.checkout_step?.user_time
      ? stepSavedData.checkout_step.user_time.split('T')[1]
      : null;
    const level =
      stepSavedData.type === 4 ? this.fitnessPoints(stepSavedData.steps) : null;
    const isWeightLoss = this.isWeightLossQuiz(stepSavedData);
    const followupKickstart = old_order_id
      ? `&old_order_id=${old_order_id}`
      : '';
    const returnUrl =
      isSales && isWeightLoss
        ? `${environment.APP_ENDPOINT}/survey/questions?current_step=100&quiz_type=${stepSavedData.type}`
        : `${environment.APP_ENDPOINT}/survey/confirmed?type=${stepSavedData.type}${followupKickstart}`;
    return {
      fitness_score: level,
      dose_id: stepSavedData?.product?.price?.med_id,
      quiz_type: stepSavedData.type,
      products: [
        {
          ...stepSavedData.product,
          quantity: 1
        }
      ],
      subscription: stepSavedData?.subscription ? true : false,
      return_url: returnUrl,
      user_date: userDate,
      user_time: userTime,
      user_id: stepSavedData.registration_step?.id,
      promocode: promocode || null,
      surcharge: stepSavedData?.surcharge === 'on' ? true : false,
      no_visit: stepSavedData?.product?.with_payment
    };
  }

  // MEMBERSHIPS
  getMemberships(filtration: any): Observable<any> {
    return this.http
      .post(`${environment.APP_ENDPOINT}/api/product/getPlans`, filtration)
      .pipe(catchError(this.apiService.handleError()));
  }

  // APPOINTMENT DATE
  getAppointmentHours(date: any, interval: string): Observable<any> {
    return this.http
      .get(
        `${environment.APP_ENDPOINT}/api/checkout/getDateScheduleBusy?date=${date}&interval=${interval}`
      )
      .pipe(catchError(this.apiService.handleError()));
  }

  // PAYMENT LINK
  getPayment(data: any): Observable<any> {
    return this.http
      .post(
        `${environment.APP_ENDPOINT}/api/checkout/createPaymentSession`,
        data
      )
      .pipe(catchError(this.apiService.handleError()));
  }

  // SALES PAYMENT LINK
  getPaymentByToken(data: any): Observable<any> {
    return this.http
      .post(
        `${environment.APP_ENDPOINT}/api/checkout/createPaymentSessionByToken`,
        data
      )
      .pipe(catchError(this.apiService.handleError()));
  }

  // SALES PAYMENT LINK
  getPaymentSession(data: any): Observable<any> {
    return this.http
      .post(`${environment.APP_ENDPOINT}/api/checkout/getPaymentSession`, data)
      .pipe(catchError(this.apiService.handleError()));
  }

  // GET REGISTRATION LINK BY SALES
  getRegisrtationLinkBySales(id: any): Observable<any> {
    return this.http
      .get(`${environment.APP_ENDPOINT}/api/user/getUserQuizLink/${id}`)
      .pipe(catchError(this.apiService.handleError()));
  }

  // SEND REGISTRATION LINK BY SALES
  sendRegisrtationLinkBySales(id: any): Observable<any> {
    return this.http
      .get(`${environment.APP_ENDPOINT}/api/user/getUserQuizLink/${id}?email=1`)
      .pipe(catchError(this.apiService.handleError()));
  }

  // GET REGISTRATION LINK BY USER
  getRegisrtationLink(): Observable<any> {
    return this.http
      .get(`${environment.APP_ENDPOINT}/api/user/getUserQuizLink`)
      .pipe(catchError(this.apiService.handleError()));
  }

  // CONFIRM PAYMENT
  checkPayment(id: string): Observable<any> {
    return this.http
      .get(
        `${environment.APP_ENDPOINT}/api/checkout/checkPaymentSession?session_id=${id}`
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err?.error);
        }),
        retry({ count: 10, delay: 3000 })
      );
  }

  // CONFIRM PAYMENT
  checkPaymentStatus(id: string): Observable<any> {
    let retryValue = this.getSavedData()?.checkout_step?.payment_retry || 0;
    return this.http
      .get(
        `${environment.APP_ENDPOINT}/api/checkout/checkUserPaymentComplete/${id}`
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.updateRetryValue();
          return throwError(() => err?.error);
        }),
        retry({ count: retryValue, delay: 10000 })
      );
  }

  updateRetryValue() {
    const savedData = this.getSavedData();
    let retryValue = savedData?.checkout_step?.payment_retry || 0;
    if (retryValue > 0) {
      retryValue -= 1;
      savedData.checkout_step.payment_retry = retryValue;
      this.localStorage.setItem(ORDER_STORAGE_KEY, JSON.stringify(savedData));
    }
  }

  // IS PAYMENT COMPLETE
  checkPaymentComplete(id: string): Observable<any> {
    return this.http.get(
      `${environment.APP_ENDPOINT}/api/checkout/checkUserPaymentComplete/${id}`
    );
  }

  // FITNESS PROGRAM
  getFitnessProgram(id: any): Observable<any> {
    return this.http
      .get(
        `${environment.APP_ENDPOINT}/api/user/getOrderFitnessScoreFile/${id}`,
        { responseType: 'blob' }
      )
      .pipe(
        map((response: any) => {
          saveAs(response, 'Fitness Program.pdf');
        }),
        catchError(this.apiService.handleError())
      );
  }

  // SEND PAYMENT LINK
  sendPaymentLink(data: any): Observable<any> {
    return this.http
      .post(`${environment.APP_ENDPOINT}/api/auth/sendPaymentLink`, data)
      .pipe(catchError(this.apiService.handleError()));
  }

  // GET USER ORDER
  getOrderById(id: any): Observable<any> {
    return this.http
      .get(`${environment.APP_ENDPOINT}/api/user/getOrder/${id}`)
      .pipe(catchError(this.apiService.handleError()));
  }

  // VALIDATE PROMOCODE
  validatePromocode(data: any): Observable<any> {
    return this.http
      .post(
        `${environment.APP_ENDPOINT}/api/checkout/checkPromocodeSeller`,
        data
      )
      .pipe(catchError(this.apiService.handleError()));
  }

  // VERIFY AFFILATE PROMOCODE
  verifyPromocode(promocode: any): Observable<any> {
    return this.http
      .post(`${environment.APP_ENDPOINT}/api/checkout/checkPromocode`, {
        promocode
      })
      .pipe(catchError(this.apiService.handleError()));
  }

  // CREATE PAYMENT LINK BY SALES
  createPaymentLinkBySales(data: any): Observable<any> {
    return this.http
      .post(
        `${environment.APP_ENDPOINT}/api/checkout/saveLastOrderSeller`,
        data
      )
      .pipe(catchError(this.apiService.handleError()));
  }

  // CHECK USER SUBSCRIPTION STATUS
  userSubscriptionStatus(): Observable<any> {
    return this.http
      .get(`${environment.APP_ENDPOINT}/api/user/checkOrderEnable`)
      .pipe(catchError(this.apiService.handleError()));
  }
}
